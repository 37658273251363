import moment from "moment";
import {
  findResource,
  findResourceUses,
  getBookingConfirmation,
  getBookingDetails,
  getModificationConfirmation,
  getCancellationConfirmation,
  getEventTimes,
  processEventDates,
  setMarketingConsent,
  API,
  findImageArray,
  getEventType,
  calcQuantForPayment,
  parseAmount,
  search,
  findAgeOfChildren
} from "../../services/";
import { GetSession, RemoveSession, SaveSession } from "../../helpers";
import { bookingTypes } from "../types";
import { pushToDataLayer } from "../../services/analytics.service";
import { DISABLE_PAYMENT, PLANYO, appDefaults } from "../../config";
import { getActiveBookings, getOldBookings, getRentalPriceInfo } from "../../services/booking.service";
import { reservationSlot } from "../../models";
import { dataLayerEvents, siteTypes } from "../../constants";
import { eventTypes } from "../../constants/eventTypes.constants";
import { CANCELLED_STATUSES, PAYMENT_STATUSES } from "../../constants/reservationStatus.constants";


const fixTime = (time) => {
  let completeTime = `${time?.split(' ')[1]?.split(':')[0]}:${time?.split(' ')[1]?.split(':')[1]}:${time?.split(' ')[1]?.split(':')[2]}`

  let hours = parseInt(completeTime.split(':')[0]);
  let minutes = completeTime.split(':')[1];
  let seconds = completeTime.split(':')[2];
  if (seconds === '59') {
    seconds = '00';
    minutes = parseInt(minutes) + 1;
  }
  if (minutes === '59' || minutes.toString() === '60') {
    minutes = '00';
    hours = hours + 1;
  } else if (minutes === '01') {
    minutes = '00';
  } else if (minutes === '31' || minutes === '29') {
    minutes = '30';
  }
  return `${hours < 10 ? `0${hours}` : hours}:${minutes}`
}

const getQuantity = (booking) => {
  const quantObj = calcQuantForPayment(
    booking?.selectedResource,
    booking?.reservationSlot,
    booking?.userDetails?.payment?.pricing_log_applied_rules?.[0],
  );
  const quant = parseAmount(quantObj?.total?.toFixed(2));
  return Math.ceil(quant);
}

export const updateReservationSlot = data => dispatch => {
  dispatch({ type: bookingTypes.RESERVATION_SLOT, payload: data });
};

export const updateUserDetails = data => dispatch => {
  dispatch({ type: bookingTypes.USER_DETAILS, payload: data });
};

export const updateEmailHash = data => dispatch => {
  dispatch({ type: bookingTypes.EMAIL_HASH, payload: data });
}

export const getBookingConfirmation1 = ({ id, resourceName, booking, app, icimId, cmdId, isPlanyoCall, dataLayerObj = {} }) => {
  return async (dispatch) => {
    try {
      getBookingConfirmation({ id, booking, app, icimId, cmdId }, isPlanyoCall, dispatch)
        .then(resp => {
          let marketInfo = {
            market: dataLayerObj?.market,
            meetingPlace: dataLayerObj?.meetingPlace,
            meetingPlaceId: dataLayerObj?.meetingPlaceId,
          }
          // page_view event
          // booking event
          let bookingEvent = {
            ...marketInfo,
            eventName: dataLayerEvents?.BOOKING,
            type: dataLayerObj?.type,
            date: dataLayerObj?.date,
            ticketNumber: dataLayerObj?.ticketNumber
          }
          pushToDataLayer(bookingEvent);
          return resp?.data;
        }).then((resp) => {
          return new Promise((resolve, reject) => {
            if ((appDefaults?.environment !== 'prod') && !DISABLE_PAYMENT && (parseInt(booking?.selectedResource?.confirmation_type) === 6)) {
              const userIdRegex = /user_id=(\d+)/;  // regex for finding the userId from the userText property of response
              const match = resp?.user_text?.match(userIdRegex);
              const userId = match ? match[1] : null;
              if (userId) {
                setMarketingConsent({ userId, value: booking?.userDetails?.marketingUpdates ? "Yes" : "No" }).then(() => {
                  resolve(resp);
                }, () => {
                  resolve(resp);
                });
              }
            } else {
              resolve(resp);
            }

          })
        }).then(resp => {
          if (
            !DISABLE_PAYMENT &&
            PAYMENT_STATUSES.includes(parseInt(resp?.status)) &&
            (parseInt(booking?.selectedResource?.confirmation_type) === 6)
            // &&
            // booking?.selectedResource?.unit_price &&
            // booking?.selectedResource?.price_type &&
            // booking?.selectedResource?.currency
          ) {
            const checkoutData = {
              'reservation_id': resp?.reservation_id,
              'customer_email': booking?.userDetails?.email,
              'quantity': getQuantity(booking),
              'name': `${booking?.selectedResource?.name}`,
              // 'description': `${app?.site?.name} - ${booking?.selectedResource?.name}`,  //used in productData.description
              'description': `${app?.site?.name} - ${booking?.selectedResource?.name}, Reservation ${resp?.reservation_id}-${booking?.selectedResource?.site_id}`, //used in paymentIntent.description
              'images': findImageArray(booking?.selectedResource?.photos),
              'calendar_id': booking?.selectedResource?.site_id,
              'currency': resp?.currency,
              'total_price': resp?.total_price,
              'unit_price': booking?.selectedResource?.unit_price,
              'locale': app?.selectedLanguage?.toLowerCase(),
              'expiresAt': moment()?.add(30, 'm').unix(),
              'category': app?.site?.type,
              'resourceId': id,
              'mpid': dataLayerObj?.meetingPlaceId
            }
            // API call for checkout page
            SaveSession('booking', JSON.stringify({ booking: { ...booking, [bookingTypes.CONFIRM_RESERVATION]: resp }, processingPayment: true, paymentReload: true }));
            stripeCheckout(checkoutData);
          } else {
            dispatch({ type: bookingTypes.CONFIRM_RESERVATION, payload: resp });
          }
          dispatch({ type: "funLoader", payload: false });
        }).catch((err) => {
          dispatch({ type: "funLoader", payload: false });
          console.log(err);
        });
    } catch (err) {
      dispatch({ type: "funLoader", payload: false });
      console.log(err);
    }
  };
}
/**
 * @function updateReduxWithReservationData
 * @description function for pushing the reservation details into redux
 * @param {object} reservationData details of the booking like date, time etc
 * @param {function} dispatch function to send action to the redux store
 * @returns {void}
 */
export const updateReduxWithReservationData = (reservationData = {}, dispatch = () => { }) => {
  dispatch(updateReservationSlot({ name: 'date', value: moment(reservationData?.start_time.split(' ')[0]).format('YYYY-MM-DD'), location: "booking.service - LN:81" }));
  dispatch(updateReservationSlot({ name: 'timeIn', value: fixTime(reservationData?.start_time), location: "booking.service - LN:82" }));
  dispatch(updateReservationSlot({ name: 'timeOut', value: fixTime(reservationData?.end_time), location: "booking.service - LN:83" }));
  dispatch(updateReservationSlot({ name: 'quantity', value: parseInt(reservationData?.quantity), location: "booking.service - LN:84" }));
  dispatch(updateReservationSlot({ name: 'unit_assignment', value: reservationData?.unit_assignment, location: "booking.service - LN:98" }));
  if (reservationData?.start_time.split(' ')[0] !== reservationData?.end_time.split(' ')[0]) {
    dispatch(updateReservationSlot({ name: 'checkOutDate', value: moment(reservationData?.end_time.split(' ')[0]).format('YYYY-MM-DD'), location: "booking.service - LN:86" }));
  }
}

/**
 * @function cleanReservationSlot
 * @description cleaning the time-related reservation details of the current booking in Redux 
 * @param {function} dispatch function to send action to the redux store
 * @returns {void}
 */
export const cleanReservationSlot = (dispatch = () => { }) => {
  dispatch({ type: bookingTypes?.CLEAN_RESERVATION_SLOT, payload: reservationSlot })
}

export const getBookingDetails1 = ({ id, language = null, siteId = null }, cb = () => { }, selector = null) => {
  return async (dispatch) => {
    try {
      getBookingDetails({ id, language }, dispatch)
        .then(async resp => {
          if (resp?.data) {
            const enteredEmail = selector?.email;
            if (CANCELLED_STATUSES.includes(parseInt(resp?.data?.status)) || resp?.response_code === 3) {
              dispatch({ type: bookingTypes.ERROR, payload: { type: 'notFound' } })
            } else if (resp?.data?.email !== enteredEmail) {
              dispatch({ type: bookingTypes.ERROR, payload: { type: 'differentEmail' } })
            } else if (moment(resp?.data?.start_time).isSameOrBefore(moment())) {
              dispatch({ type: bookingTypes.ERROR, payload: { type: 'expired' } })
            } else {
              let prefix = (resp?.data?.phone_number?.slice(0, 1) + "+" + resp?.data?.phone_number?.slice(1))?.replace("-", " ");
              dispatch({ type: bookingTypes.ERROR, payload: null })
              dispatch(updateUserDetails({ name: 'name', value: resp?.data?.first_name }));
              dispatch(updateUserDetails({ name: 'surname', value: resp?.data?.last_name }));
              dispatch(updateUserDetails({ name: 'email', value: resp?.data?.email }));
              dispatch(updateUserDetails({ name: 'phone', value: prefix }));
              dispatch(updateUserDetails({ name: 'store', value: resp?.data?.properties?.Store }))
              dispatch(updateUserDetails({ name: 'DNI', value: resp?.data?.properties?.DNI }))
              dispatch(updateUserDetails({ name: 'rental_prop_children', value: resp?.data?.properties?.children }))
              dispatch(updateUserDetails({ name: 'rental_prop_Age_of_children', value: findAgeOfChildren(resp?.data?.properties?.children, 'Age_of_children_', resp?.data?.properties) }))
              updateReduxWithReservationData(resp?.data, dispatch);
            }

            dispatch({
              type: bookingTypes.CONFIRM_RESERVATION, payload: {
                resource_id: resp?.data?.resource_id,
                reservation_id: id,
                edited: false,
                cancelled: false,
                currency: resp?.data?.currency,
                amountPaid: resp?.data?.amount_paid,
                status: resp?.data?.status
              }
            });
            dispatch({ type: bookingTypes.BOOKINGS, payload: { reservationId: id } })

            cb(resp?.data);
          } else {
            if (resp?.errors !== "REQUEST_CANCELLED") {
              dispatch({ type: bookingTypes.ERROR, payload: { type: 'notFound' } })
            }
            cb(null);
          }
        })
    } catch (err) {
      console.log(err);
    }
  };
};

export const getModificationConfirmation1 = ({ id, booking }) => {
  return async (dispatch) => {
    try {
      getModificationConfirmation({ id, booking }, dispatch)
        .then(resp => {
          !PLANYO ? dispatch({ type: bookingTypes.EDIT_RESERVATION, payload: resp?.data[id]?.data })
            :
            dispatch({ type: bookingTypes.EDIT_RESERVATION, payload: resp?.data })
          dispatch({ type: 'funLoader', payload: false });
        }).catch((err) => {
          if (err.response) {
          }
        });
    } catch (err) {
      dispatch({ type: 'funLoader', payload: false })
      console.log(err);
    }
  };
}

export const getCancellationConfirmation1 = ({ id }, dispatch) => {
  // debugger
  dispatch({ type: 'funLoader', payload: true })
  return async (dispatch) => {
    try {
      getCancellationConfirmation({ id }, dispatch)
        .then(resp => {
          dispatch({ type: bookingTypes.CANCEL_RESERVATION, payload: true });
          dispatch({ type: 'funLoader', payload: false })

        }).catch((err) => {
          if (err.response) {
          }
        });
    } catch (err) {
      dispatch({ type: 'funLoader', payload: false })
      console.log(err);
    }
  };
}

export const getResourceDetails1 = ({ id, year, month, language, category, noOfMonths = 1, skipUsage = false }, notCleanBookingData = false) => {
  return async (dispatch) => {
    try {
      if (!notCleanBookingData) {
        dispatch({ type: bookingTypes.CLEAN_BOOKING_DETAILES });
      }
      findResource({ id, year, month, language: language, category, noOfMonths, skipUsage }, dispatch)
        .then(resp => {
          dispatch({ type: bookingTypes.SELECTED_RESOURCE, payload: resp });
        }).catch((err) => {
          if (err.response) {
            // dispatch(
            //   enqueueSnackbar({
            //     message: err.response.data?.error,
            //     options: {
            //       key: new Date().getTime() + Math.random(),
            //       variant: "error",
            //     },
            //   })
            // );
            // dispatch({ type: AppActions.HIDE_PB });
            // throw new Error(err.response.data?.error);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
}


export const getResourceUses = ({ id, date, endDate }, notCleanBookingData = false) => {
  return async (dispatch) => {
    try {
      if (!notCleanBookingData) {
        dispatch({ type: bookingTypes.CLEAN_BOOKING_DETAILES });
      }
      findResourceUses({ id, date, endDate }, dispatch)
        .then(resp => {
          dispatch({ type: bookingTypes.SELECTED_RESOURCE, payload: resp });
        }).catch((err) => {
          if (err.response) {
            // dispatch(
            //   enqueueSnackbar({
            //     message: err.response.data?.error,
            //     options: {
            //       key: new Date().getTime() + Math.random(),
            //       variant: "error",
            //     },
            //   })
            // );
            // dispatch({ type: AppActions.HIDE_PB });
            // throw new Error(err.response.data?.error);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export const getEventDetails1 = (data, isEdit = false) => {
  return async (dispatch) => {
    try {
      if (!isEdit) {
        dispatch({ type: bookingTypes.CLEAN_BOOKING_DETAILES });
      }
      dispatch({ type: "funLoader", payload: true });
      findResource({ id: data?.id, year: data?.year, month: data?.month, language: data?.language, category: data?.category || siteTypes?.EVENT, skipUsage: true }, dispatch)
        .then(resp => {
          getEventTimes({ id: data?.id }, dispatch)
            .then(times => {
              let listEventTimes = [];
              // let keys = (!PLANYO) ? times : times ? Object?.keys(times?.event_times) : times;
              let keys = times;
              //processing event dates
              // regex for finding whether the date format is like April 20,2023 || Apr 20, 2023
              const eventDateRegex = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{1,2},\s\d{4}\b/;
              let isExclusiveCase = eventDateRegex.test(resp?.event_dates);
              const processedEventDates = processEventDates(isExclusiveCase ? resp?.event_dates?.replace(',', '')?.split("#") : resp?.event_dates?.split(","));
              const eventType = getEventType(processedEventDates, parseInt(resp?.min_rental_time) > 24);
              let event_dates = processedEventDates?.toString();
              const respTime = times?.map(current => current?.text);
              let processedTimes = processEventDates(respTime);
              resp = {
                ...resp,
                event_dates
              }

              if (keys?.length < 1) {
                let eventDates = [];
                let isPeriodic = false;
                if (resp?.event_dates?.includes("daily") || resp?.event_dates?.includes("weekly") || resp?.event_dates?.includes("monthly")) {
                  eventDates = resp?.event_dates?.split(" * ");
                  isPeriodic = true;
                } else {
                  eventDates = resp?.event_dates?.split(",");
                }
                let timeDetails = {
                  endDate: moment(new Date()).format("YYYY-MM-DD"),
                  timeIn: "00:00",
                  timeOut: "23:59",
                  label: "",
                }

                if (eventDates?.length > 1) {
                  let days;
                  let tempStartDate;
                  if (isPeriodic) {
                    days = parseInt(eventDates[0]);
                    tempStartDate = eventDates[1]?.replace('daily', '')?.replace('weekly', '')?.replace('monthly', '');
                  }
                  let startDate = isPeriodic ? moment(tempStartDate).format("YYYY-MM-DD") : moment(eventDates[eventDates.length - 1])?.format("YYYY-MM-DD");
                  timeDetails = {
                    endDate: isPeriodic ? moment(startDate, "YYYY-MM-DD").add(days - 1, 'days') : startDate,
                    timeIn: (resp?.start_hour && +(resp.start_hour) < 10) ? ('0' + resp?.start_hour + ':00') : (resp?.start_hour + ':00'),
                    timeOut: resp?.end_hour === '24' ? '23:59' : ((+(resp.end_hour) < 10) ? ('0' + resp?.end_hour) : (resp?.end_hour)) + ':00',
                    label: moment(startDate)?.format('HH:mm, YYYY-MM-DD')
                  }

                } else {
                  timeDetails = {
                    endDate: (eventDates?.length > 0) ? moment(eventDates[0])?.format("YYYY-MM-DD") : "",
                    timeIn: (resp?.start_hour && +(resp.start_hour) < 10) ? ('0' + resp?.start_hour + ':00') : (resp?.start_hour + ':00'),
                    timeOut: resp?.end_hour === '24' ? '23:59' : ((+(resp.end_hour) < 10) ? ('0' + resp?.end_hour) : (resp?.end_hour)) + ':00',
                    label: moment(timeDetails?.endDate)?.format('HH:mm, YYYY-MM-DD'),
                  }
                }
                listEventTimes.push({
                  date: timeDetails?.endDate,
                  quantity: resp?.quantity,
                  timeIn: timeDetails?.timeIn,
                  timeOut: (timeDetails?.timeOut === '00:00') ? '24:00' : timeDetails?.timeOut,
                  label: timeDetails?.label
                })
              }

              let isSoldOut = true;
              for (let index = 0; index < keys?.length; index++) {
                let bookedDate = moment(data?.bookingDate, "YYYY-MM-DD")?.add(moment.duration(data?.bookingTimeIn))
                // let tempQuantity = ((!PLANYO) ?
                //   (data?.editBooking ? ((times[index]?.text === bookedDate?.format("YYYY-MM-DD HH:mm")) ? parseInt(times[index]?.available) + data?.bookingQuantity : parseInt(times[index]?.available)) : parseInt(times[index]?.available))
                //   :
                //   (data?.editBooking ? ((times?.event_times[keys[index]]?.text === bookedDate?.format("YYYY-MM-DD HH:mm")) ? parseInt(times?.event_times[keys[index]]?.available) + data?.bookingQuantity : parseInt(times?.event_times[keys[index]]?.available)) : parseInt(times?.event_times[keys[index]]?.available)));
                let tempQuantity = data?.editBooking ? ((processedTimes[index] === bookedDate?.format("YYYY-MM-DD HH:mm")) ? parseInt(times[index]?.available) + data?.bookingQuantity : parseInt(times[index]?.available))
                  :
                  parseInt(times[index]?.available)
                const quant = tempQuantity
                // const startTime = (!PLANYO) ? moment(times[index]?.text) : moment(times?.event_times[keys[index]]?.text);
                const startTime = moment(processedTimes[index]);
                let endTime = moment(startTime).add(resp?.min_rental_time, 'h');
                if (endTime.format('HH:mm') === '00:00') {
                  endTime?.subtract(1, 'm');
                }
                const currTime = moment();

                if (currTime.isBefore(endTime) && (quant > 0)) {
                  listEventTimes.push({
                    date: startTime.format('YYYY-MM-DD'),
                    quantity: quant,
                    timeIn: startTime.format('HH:mm'),
                    timeOut: (endTime.format('HH:mm') === '00:00') ? '24:00' : endTime.format('HH:mm'),
                    checkOutDate: endTime.format('YYYY-MM-DD'),
                    label: eventType === eventTypes.DIFF_TIME
                      ? `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`
                      : (
                        [eventTypes.DIFF_DAY, eventTypes.PERIODIC_DAILY, eventTypes.PERIODIC_WEEKLY, eventTypes.PERIODIC_MONTHLY].includes(eventType)
                          ? startTime.format('ddd, DD MMM YYYY')
                          : `${startTime.format('ddd, DD MMM YYYY | HH:mm')} - ${endTime.format('HH:mm')}`
                      )
                  })
                }
                if (quant > 0) {
                  isSoldOut = false;
                }
              }
              if (data?.editBooking && data?.bookingDate && data?.bookingQuantity) {
                resp = { ...resp, quantity: listEventTimes.find(item => moment(item.date).isSame(moment(data?.bookingDate)))?.quantity };
              } else {
                resp = { ...resp, quantity: listEventTimes[0]?.quantity };
              }
              if (resp?.confirmation_type !== "5" &&
                resp?.confirmation_type !== "6" &&
                resp?.properties?.is_free !== "no") {
                resp.isFreeEvent = true
              }
              dispatch({ type: bookingTypes.SELECTED_RESOURCE, payload: resp });
              dispatch({ type: bookingTypes.EVENT_TYPE, payload: eventType });
              const isBookingRestored = GetSession("isBookingRestored");
              if (!data?.editBooking && !isBookingRestored) {
                dispatch(updateReservationSlot({ name: 'date', value: listEventTimes[0]?.date, location: "booking.service - LN:245" }));
                dispatch(updateReservationSlot({ name: 'timeIn', value: listEventTimes[0]?.timeIn, location: "booking.service - LN:246" }));
                dispatch(updateReservationSlot({ name: 'timeOut', value: listEventTimes[0]?.timeOut, location: "booking.service - LN:247" }));
                dispatch(updateReservationSlot({ name: 'checkOutDate', value: listEventTimes[0]?.checkOutDate, location: "booking.service - LN:247" }));
              }
              RemoveSession("isBookingRestored");
              if (keys?.length > 0 && isSoldOut) {
                dispatch(updateReservationSlot({ name: 'isSoldOut', value: true, location: "booking.service - LN:245" }))
              } else if (keys?.length === 0) {
                dispatch(updateReservationSlot({ name: 'isPastEvent', value: true }))
              }

              if (listEventTimes?.length > 0) {
                dispatch(updateReservationSlot({ name: 'eventAvailableDates', value: listEventTimes, location: "booking.service - LN:245" }))
              }

            }).then(() => {
              dispatch({ type: "funLoader", payload: false });
            })

        }).catch((err) => {
          dispatch({ type: "funLoader", payload: false });
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
}

/* 
  removed commented function
  functionName: getEventDetails1
  last commitID: 9f1a37b60eb414d1f402748cbd736798532ecb4a
*/

export const pushSelectedResource = (resource, id, dispatch = () => { }) => {
  const selectedResource = resource?.list.find(item => item.id === id);
  if (selectedResource) {
    dispatch({ type: "selectedResource", payload: selectedResource });
  }
}

export const setB2bUserDetails = (details) => {
  SaveSession('b2bUserDetails', JSON.stringify(details));
  return {
    type: bookingTypes.B2B_USER_DETAILS,
    payload: details,
  };
};

/**
 * @function setActiveBookings
 * @description Sets the active bookings for a user in redux store after fetching them
 * @param {string} userId User's planyo user ID
 * @param {string} startDate The start date is used to filter the bookings, where only the bookings with start dates after this value are fetched 
 * @param {string} endDate The end date is used to filter the bookings, where only the bookings with end dates before this value are fetched
 * @returns {function} Anonymous function that performs the specified action
 */
export const setActiveBookings = (userId, startDate, endDate) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: "funLoader", payload: true });
      getActiveBookings({ userId, startDate, endDate }, dispatch)
        .then(resp => {
          const currTime = moment();
          dispatch({
            type: bookingTypes.SET_ACTIVE_BOOKINGS,
            payload: resp?.data?.results !== null ? resp?.data?.results?.filter(booking => {
              const bookingEndTime = moment(booking?.end_time);
              return currTime?.isBefore(bookingEndTime);
            }) : []
          });
          dispatch({ type: "funLoader", payload: false });
        }).catch((err) => {
          console.log(err);
          dispatch({ type: "funLoader", payload: false });
        });
    } catch (err) {
      console.log(err);
    }
  }
}

/**
 * @function setOldBookings
 * @description Sets the old bookings for a user in redux store after fetching them
 * @param {string} userId User's planyo user ID
 * @param {string} startDate The start date is used to filter the bookings, where only the bookings with start dates after this value are fetched 
 * @returns {function} Anonymous function that performs the specified action
 */
export const setOldBookings = (userId, startDate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "funLoader", payload: true });
      getOldBookings({ userId, startDate }, dispatch)
        .then(resp => {
          dispatch({ type: bookingTypes.SET_OLD_BOOKINGS, payload: resp?.data?.results });
          dispatch({ type: "funLoader", payload: false });
        }).catch((err) => {
          console.log(err);
          dispatch({ type: "funLoader", payload: false });
        });
    } catch (err) {
      console.log(err);
    }
  }
}

/**
 * @function stripeCheckout
 * @description sends an API call to retrieve the payment url, where the user will be redirected to carry out the payment process
 * @param {object} checkoutData contains parameters related to the user's reservation: reservation_id, customer_mail, quantity, resource name, site_id, currency, total_price
 * @returns {void}  
 */
export const stripeCheckout = async (checkoutData) => {
  let successUrl = '';
  let href = window?.location?.href?.split('/');
  href.splice(6, href.length - 1, `brp/book/resourcestatus/${checkoutData?.reservation_id}`)
  successUrl = href?.join('/');
  let amount = (parseFloat(checkoutData?.total_price / checkoutData?.quantity)?.toFixed(2)) * 100;
  // debugger
  // let decimalAmount = amount - Math.floor(amount);
  // if (decimalAmount > 0.50) {
  //   amount = Math.ceil(amount) * 100;
  // } else {
  //   amount = Math.floor(amount) * 100;
  // }
  amount = parseInt(amount);

  if (!PLANYO) {
    const resp = await search({
      method: "create_checkout_session",
      reservationId: checkoutData?.reservation_id,
      category: checkoutData?.category,
      resourceName: checkoutData?.name,
      quantity: checkoutData?.quantity,
      currency: checkoutData?.currency,
      amount: amount,
      id: checkoutData?.resourceId
    })
    if (resp?.data?.url) {
      window.location.href = resp?.data?.url;
    }
  } else {
    await API.post({
      path: "../../api/create-checkout-session",
      body: {
        "mpid": checkoutData?.mpid,
        "line_items": [{
          "price_data": {
            "currency": checkoutData?.currency,
            "product_data": {
              "name": checkoutData?.name,
              // "description": checkoutData?.description,
              // "images": checkoutData?.images
            },
            "unit_amount": amount
          },
          "quantity": checkoutData?.quantity
        }],
        "mode": "payment",
        "invoice_creation": {
          "enabled": true,
        },
        "automatic_tax": {
          "enabled": true
        },
        "success_url": successUrl,
        "cancel_url": window?.location?.href,
        "client_reference_id": `${checkoutData?.reservation_id}-${checkoutData?.calendar_id}`,
        // "customer_email": checkoutData?.customer_email,  // Commented by Naveen at 16-10-2023 based on BEGL-1336
        "expires_at": checkoutData?.expiresAt?.toString(),
        "locale": checkoutData?.locale,
        "payment_intent_data": {
          "metadata": {
            calendar_id: parseInt(checkoutData?.calendar_id),
            rental_id: checkoutData?.reservation_id
          },
          "description": checkoutData?.name,
          // "receipt_email": checkoutData?.customer_email   //Removing this based on BEGL-1336 by Kunal Aggarwal
        }
      },
      key: "CREATE-CHECKOUT-SESSION"
    })?.then(resp => {
      if (resp?.data?.url) {
        window.location.href = resp?.data?.url;
      }
    });
  }
}

export const storeRentalPriceInfo = (resourceId, reservationSlot, resourceType) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "funLoader", payload: true });
      const startDateM = moment(reservationSlot?.date);
      let endDateM;
      if (reservationSlot?.timeIn) {
        startDateM?.set({ 'hour': reservationSlot?.timeIn?.split(':')?.[0], 'minute': reservationSlot?.timeIn?.split(':')?.[1] });
      }
      if (reservationSlot?.checkOutDate) {
        endDateM = moment(reservationSlot?.checkOutDate);
        if (resourceType === 'weekly') endDateM?.hour('23')?.minute('59');
      } else {
        endDateM = moment(reservationSlot?.date)?.set({ 'hour': reservationSlot?.timeOut?.split(':')?.[0], 'minute': reservationSlot?.timeOut?.split(':')?.[1] });
      }
      getRentalPriceInfo({ resourceId, startDate: startDateM, endDate: endDateM, quantity: reservationSlot?.quantity }, dispatch)
        .then(resp => {
          dispatch({ type: bookingTypes.USER_DETAILS, payload: { name: 'payment', value: resp?.data } });
          dispatch({ type: "funLoader", payload: false });
        }).catch((err) => {
          console.log(err);
          dispatch({ type: "funLoader", payload: false });
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export const getBookingQrCode = ({ bookingId, siteId }) => {
  return search({
    method: "process_template",
    reservationId: bookingId,
    siteId,
    template: encodeURIComponent(`$(qrcode:${window.location.origin}/qr-action?action=Checkin&resource=$(resource_id)&reservation=$(rental_id)&site=$(site_id))`),
    // headers: { Planyo: true }
  })
}